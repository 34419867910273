<template>
  <article
    :class="{ 'is-alpha': isAlpha }"
    class="agent"
    @click="$emit('clicked')"
  >
    <b-checkbox
      v-if="selectable"
      :value="checked"
      class="has-margin-left-50"
      @change.native="$emit('checkChanged', { event: $event, agentId })"
    />

    <avatar
      :name="agentName"
      :avatar="agentAvatarUrl"
      :size="35"
      class="has-margin-left-50"
    />

    <p class="has-margin-left-100 has-overflow-ellipsis-right">
      {{ agentName }}
    </p>

    <b-tag
      v-if="isAlpha"
      class="has-margin-left-50 has-margin-right-50"
      type="is-primary"
      >Lead agent</b-tag
    >

    <b-icon
      v-if="closeable"
      pack="fas"
      icon="times-circle"
      class="has-text-danger has-cursor-pointer justify-end"
      @click.native.stop="$emit('close', agentId)"
    />

    <b-icon
      v-if="allowAdd"
      pack="fas"
      icon="plus-circle"
      class="has-text-success has-cursor-pointer justify-end"
      @click.native.stop="$emit('add', agentId)"
    />
  </article>
</template>

<script>
export default {
  name: "AgentUserRow",
  props: {
    agentId: {
      type: String,
      required: true
    },
    agentName: {
      type: String,
      required: true
    },
    agentAvatarUrl: {
      type: String,
      required: false,
      default: ""
    },
    isAlpha: {
      type: Boolean,
      required: false,
      default: false
    },
    checked: {
      type: Boolean,
      required: false,
      default: false
    },
    selectable: {
      type: Boolean,
      required: false,
      default: false
    },
    closeable: {
      type: Boolean,
      required: false,
      default: false
    },
    allowAdd: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@sass/bulma/custom-variables";

.agent {
  background-color: $light;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  padding: 0.5em 0.5em 0.5em 0.5em;
  align-items: center;
  margin: 0 0 0 0;
  display: flex;
  justify-content: flex-start;

  picture {
    min-width: 32px;
  }
  .justify-end {
    margin-left: auto;
  }

  .icon {
    flex-basis: auto;
  }
}
</style>
